import React from "react";
import resumeFile from "../documents/output.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2022 - 2024",
      title: "Executive Masters in Information Security",
      place: "University of the Cumberlands",
      desc: "Security from a business perspective, with a focus on risk management and compliance.",
    },
    {
      yearRange: "2017 - 2019",
      title: "Master of Computer Networking",
      place: "North Carolina State University",
      desc: "Deep dive into Networking and Security with first a principle mindset",
    },
    {
      yearRange: "2013 - 2017",
      title: "Computer Science & Engineering",
      place: "Amrita University",
      desc: "Fundamentals of Computer Science and Engineering.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2022 - Present",
      title: "Founder",
      place: "Psync",
      desc: "Building the new age of long-term connections. \n\
      Psync elevates you to your best self. \n\
      The rest is just noise.",
    },
    {
      yearRange: "2021 - 2022",
      title: "DevOps Engineer",
      place: "Fortanix",
      desc: "Managed 'production-grade' Kubernetes Clusters across AMER | APAC | EU | AU.",
    },
    {
      yearRange: "2019 - 2021",
      title: "Member of Technical Staff",
      place: "VMware",
      desc: "Worked on NSX-T, our virtual networking solution; On-prem & Federation; NDcPP security compliance.",
    },
    {
      yearRange: "2018 - 2020",
      title: "Research Assistant, NC State",
      place: "Wolfpack Security and Privacy Research Lab",
      desc: "Worked on: \n\
      1) Telephony Security - AuthentiCall \n\
      2) Github notification security - GitNotified \n\
      3) Detection of countries from where a phone call originates based off of ENF in the background noise.",
    },
    {
      yearRange: "2018",
      title: "Wireless Security Intern",
      place: "Aruba Networks",
      desc: "Analyzed contiguous core dump snapshots to determine memory corruption errors and anomalous behavior in code",
    },
  ];

  const skills = [
    {
      name: "Performance",
      percent: 90,
    },
    {
      name: "Networking & Security",
      percent: 85,
    },
    {
      name: "System Design",
      percent: 80,
    },
    {
      name: "Docker/Kubernetes",
      percent: 75,
    },
    {
      name: "Rust",
      percent: 65,
    },
    {
      name: "Python",
      percent: 97,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <div className="text-center text-md-start">
              <h2
                className={
                  "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
                }
              >
                My Education
              </h2>
            </div>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <div className="text-center text-md-start">
              <h2
                className={
                  "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
                }
              >
                My Experience
              </h2>
            </div>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
{/* My Skills Section */}
<div className="text-center text-md-start">
  <h2 className={`text-6 fw-600 mt-4 mb-4 ${darkTheme ? "text-white" : ""}`}>
    My Skills
  </h2>
</div>

<div className="row gx-5">
  {skills.length > 0 && skills.map((skill, index) => (
    <div key={index} className="col-md-6">
      <p className={`fw-500 text-start mb-2 ${darkTheme ? "text-light" : "text-dark"}`}>
        {skill.name} <span className="float-end">{skill.percent}%</span>
      </p>
      <div className={`progress progress-sm mb-4 ${darkTheme ? "bg-dark" : ""}`}>
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${skill.percent}%` }}
          aria-valuenow={skill.percent}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </div>
  ))}
</div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
