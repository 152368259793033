import React from "react";

const Disclaimer = ({ darkTheme }) => {
  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Copyright &amp; Disclaimer
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
               The information contained on this website is for general information purposes only.
               The information is provided by Srikanth and while we endeavor to keep the information up to date and correct,
               we make no representations or warranties of any kind, express or implied, about the completeness, accuracy,
               reliability, suitability or availability with respect to the website or the information, products, services,
               or related graphics contained on the website for any purpose. Any reliance you place on such information
               is therefore strictly at your own risk.
            </p>
            <ul className="lh-lg">
              <li>
                In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
              </li>
              <li>
                Through this website you are able to link to other websites which are not under the control of Srikanth. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
              </li>
              <li>
                All content provided on this website is for informational purposes only and should not be considered legal or professional advice. You should consult with an attorney or other professional to determine what may be best for your individual needs.
              </li>
              <li>
                The website owner Srikanth will not be held responsible for any errors or omissions in the information provided on this website.
              </li>
              <li>
                The website owner Srikanth reserves the right to make changes to the website and the terms of use at any time without notice.
              </li>
              <li>
                The website owner Srikanth will not be held liable for any damages resulting from the use of this website or the inability to use this website.
              </li>
              <li>
                By using this website, you agree to indemnify and withhold the website owner Srikanth and its employees, agents and affiliates from any claim or demand, including reasonable attorney fees, made by any third party due to or arising out of your use of this website.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
