import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
<div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p>
            This section contains the terms and conditions that govern your use of this website and the services offered by Srikanth. By accessing or using this website, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you should not use this website.
            </p>
            <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Terms of Use
            </h3>
            <p>
              This section outlines the terms of use for this website. By accessing or using this website, you agree to be bound by these terms. If you do not agree to these terms, you should not use this website.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part I – Information Srikanth collects and controls
            </h5>
            <p>
              This section outlines the types of information that Srikanth collects and controls when you use this website. This may include personal information, such as your name and contact details, as well as information about your use of the website and the services offered.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part II – Information that Srikanth processes on your behalf
            </h5>
            <p>
              This section outlines the types of information that Srikanth may process on your behalf when you use this website. This may include information related to transactions or orders placed through the website, as well as any communications you have with Srikanth.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
            Part III – General
            </h5>
            <p>
            This section contains general information about our website and services, including any limitations of liability, and any disputes that may arise. By accessing or using this website, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you should not use this website.
            </p>
            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
            Privacy Policy
            </h3>
            <p>
            This section contains our privacy policy, which outlines how we collect, use, and protect your personal data in accordance with the General Data Protection Regulation (GDPR).
            </p>
            <ol className="lh-lg">
            <li>
            We collect personal data such as your name, email address, and telephone number when you visit our website or interact with us through forms or other means.
            </li>
            <li>
            We use the personal data we collect to provide and improve our website and services, respond to your inquiries, send you important information, personalize your experience and conduct research and analysis.
            </li>
            <li>
            We may share your personal data with third-party service providers who provide services on our behalf, business partners and other organizations, and as required by law or legal request.
            </li>
            <li>
            We will retain your personal data for as long as needed to provide our services, comply with legal obligations, resolve disputes and enforce agreements.
            </li>
            <li>
            We take appropriate security measures to protect your personal data against unauthorized access, alteration, disclosure or destruction.
            </li>
            <li>
            You have the right to access, correct, delete, restrict, and object to the processing of your personal data, as well as the right to data portability.
            </li>
            <li>
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website.
            </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
