import React, { useRef, useState } from "react";
import Turnstile from "react-turnstile";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";


import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

// NOTE: Double check if this gets the gets the correct IP address
const getClientIP = async () => {
  const response = await fetch('https://ipapi.co/json/');
  const data = await response.json();
  return data.ip;
};

const Contact = ({ classicHeader, darkTheme }) => {
  const form = useRef();

  const [sendingMail, setSendingMail] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState(null);

  const validateTurnstileToken = async (ip) => {
    const result = await fetch('https://turnstile.gsrikanth.cc', {
      method: 'POST',
      headers: {
        'cf-turnstile-response': turnstileToken,
        'cf-turnstile-ip': ip,
      },
    });

    if (result.status === 200) {
      return true;
    } else {
      // If the response is not plain text or if the status is not 200,
      // you can still try to parse the JSON response and return the 'success' property
      try {
        const outcome = await result.json();
        return outcome.success;
      } catch (error) {
        // If parsing JSON fails, return false
        return false;
      }
    }
  };


  const sendEmail = async (e) => {
    e.preventDefault();
    setSendingMail(true);

    const form = e.target;

    //console.log(turnstileToken);

    getClientIP().then((ip) => {
      validateTurnstileToken(ip)
        .then((isValid) => {
          if (isValid) {
            emailjs
              .sendForm(
                'service_0as8nvo',
                'template_eu4jidr',
                form,
                'B0RMVK9x7M5KXkRCh',
                {
                  accessToken: 'lfYoqodzBsPH5mhdCPev4', // private key
                }
              )
              .then(
                (result) => {
                  form.reset();
                  toast.success('Email Sent!', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: darkTheme ? 'dark' : 'light',
                  });
                  setSendingMail(false);
                },
                (error) => {
                  form.reset();
                  toast.error('Use my contact. Still working on this..', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: darkTheme ? 'dark' : 'light',
                  });
                  setSendingMail(false);
                }
              );
          } else {
            toast.error('Turnstile validation failed.', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: darkTheme ? 'dark' : 'light',
            });
            setSendingMail(false);
          }
        });
    }).catch((error) => {
      console.error('Error occurred:', error);
      setSendingMail(false);
    });
  };


  return (
    <section
      id="contact"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Contact
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Get in Touch
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* contact details */}
          <div className="col-md-4 col-xl-3 order-1 order-md-0 text-center text-md-start">
            <h2
              className={
                "mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Address
            </h2>
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              Spirit World
              <br />
              A.K.A. Remote life
              <br />
            </p>
            <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-phone" />
              </span>
              +1 (919)-946-6057
            </p>
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-envelope" />
              </span>
              chat@gsrikanth.cc
            </p>
            <h2
              className={
                "mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Follow Me
            </h2>
            <ul
              className={
                "social-icons justify-content-center justify-content-md-start " +
                (darkTheme ? "social-icons-muted" : "")
              }
            >
              <li className="social-icons-instagram">
                <Tooltip text="Instagram" placement="top">
                  <a
                    href="https://instagram.com/soulfull_science/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fa-brands fa-instagram fa-shake" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-twitter">
                <Tooltip text="Twitter" placement="top">
                  <a
                    href="https://twitter.com/SeetKu/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-linkedin">
                <Tooltip text="LinkedIn" placement="top">
                  <a
                    href="http://www.linkedin.com/in/sgopala8/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-github">
                <Tooltip text="Github" placement="top">
                  <a
                    href="http://www.github.com/xchikux"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-airchat">
                <Tooltip text="AirChat" placement="top">
                  <a
                    href="https://www.air.chat/gsrikanth"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-mastodon" />
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
          {/* contact form */}
          <div className="col-md-8 col-xl-9 order-0 order-md-1">
            <h2
              className={
                "mb-3 text-5 text-uppercase text-center text-md-start " +
                (darkTheme ? "text-white" : "")
              }
            >
              Send me a note
            </h2>
            <form
              className={darkTheme ? "form-dark" : ""}
              id="contact-form"
              action="php/mail.php"
              method="post"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="row g-4">
                <div className="col-xl-6">
                  <input
                    name="user_name"
                    type="text"
                    className="form-control"
                    required
                    placeholder="Name"
                  />
                </div>
                <div className="col-xl-6">
                  <input
                    name="user_email"
                    type="email"
                    className="form-control"
                    required
                    placeholder="Email"
                  />
                </div>
                <div className="col">
                  <textarea
                    name="message"
                    className="form-control"
                    rows={5}
                    required
                    placeholder="Get in touch by detailing what you are looking for........"
                    defaultValue={""}
                  />
                </div>
              </div>
              <Turnstile className="text-center mt-4 mb-0"
                sitekey="0x4AAAAAAAB-OGubILf0feLc"
                onVerify={(token) => setTurnstileToken(token)}
              />
              <p className="text-center mt-4 mb-0">
                <button
                  id="submit-btn"
                  className="btn btn-primary rounded-pill d-inline-flex"
                  type="submit"
                >
                  {sendingMail ? (
                    <>
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm align-self-center me-2"
                      ></span>
                      Sending.....
                    </>
                  ) : (
                    <>Send Message</>
                  )}
                </button>
              </p>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div >
    </section >
  );
};

export default Contact;
