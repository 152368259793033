import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // services details
  const services = [
    {
      name: "Security",
      desc: "End-to-end encryption using public key cryptography, \
             Argon2 password hashing, Blake3 hash verification. \
             Would you like some salt with that?",
      icon: "fa-solid fa-key",
    },
    {
      name: "Networking",
      desc: "NSX-T, Distributed Switches (VDS), Overlay Networking,\
             Network Namespaces, Linux Networking, NAT, Port Forwarding, Wireguard, DNS,\
             DHCP, NFtables Firewall, Load Balancing, and a lot more.",
      icon: "fa-solid fa-network-wired",
    },
    {
      name: "Parallel & Async Computing ",
      desc: "Multi-threading, Multi-processing, and Asynchronous Programming in Python and Rust.\
             The only time 'scheduling' is fun.",
      icon: "fa-solid fa-group-arrows-rotate",
    },
    {
      name: "App Design & Development",
      desc: "Eye for detail, writing clean, maintainable code, and a passion\
             for getting things right the first time.",
      icon: "fa-brands fa-dev",
    },
    {
      name: "DevSecOps and SRE",
      desc: "Docker, Kubernetes, Terraform, Github Actions, and more. Let's automate the boring stuff away.",
      icon: "fa-sharp fa-solid fa-toolbox",
    },
    {
      name: "Research",
      desc: "Pandas, Numpy, Matplotlib, Seaborn and a burning desire to never stop learning.\
             Oh boy, do my graphs look good!",
      icon: "fa-brands fa-researchgate",
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do?
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
