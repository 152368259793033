import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    SYSTEM: "System",
    SECURITY: "Security",
    RESEARCH: "Research",
    FULLSTACK: "Fullstack",
  };

  const projectsData = [
    {
      title: "L.I.S.P. RFC 6830",
      projectInfo:
        "LISP which is 'map-and-encapsulate' protocol was invented to reduce \
         the load and increase the scalability of routing table entries in a \
         generic routers used across the internet.\n \
         OpenOverlayRouter was used for implementing a traditional LISP topology, \
         Then Ryu and OVS was used to perform the same thing in an SDN based approach ",
      client: "NCSU - Dr. Rudra Dutta",
      technologies: "OpenFlow, Ryu, Open Overlay Router, Exogeni, OSPF",
      industry: "Networking Protocols, Distributed Systems",
      date: "March 16, 2018",
      url: {
        name: "SDN Project",
        link: "https://sites.google.com/ncsu.edu/lisp/home",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/XChikuX",
        twitter: "http://www.twitter.com/CipherSpiegel",
        instagram: "http://www.instagram.com/soulfull_science/",
        mail: "mailto:chat@gsrikanth.cc",
      },
      thumbImage: "images/projects/project-1.jpg",
      sliderImages: [
        "images/projects/lisp/High Level.jpg",
        "images/projects/lisp/SDN Design.jpg",
        "images/projects/lisp/OOR Design.jpg",
        "images/projects/lisp/Wireshark.jpg",
        "images/projects/lisp/Ryu.jpg",
        "images/projects/lisp/Network Design.jpg",
      ],
      categories: [filters.SYSTEM],
    },
    {
      title: "Linux Kernel Modules",
      projectInfo:
        "1) Made a round robin time scheduler for processes to act as a container. \n\
         2) Created a shared memory space for processes within containers. \n\
         3) Wrote NetFilter hooks to modify the behaviour of some specific TCP flows.",
      client: "NCSU - Dr. Hung-Wei Tseng",
      technologies: "C, Linux, NetFilter",
      industry: "Systems",
      date: "March 10, 2018",
      url: {
        name: "Blog",
        link: "https://gsrikanth.cc/blog/challenger-deep/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/XChikuX",
        twitter: "http://www.twitter.com/CipherSpiegel",
        instagram: "http://www.instagram.com/soulfull_science/",
        mail: "mailto:chat@gsrikanth.cc",
      },
      thumbImage: "images/projects/project-2.jpg",
      sliderImages: [
        "images/projects/project-2.jpg",
        "images/projects/linux kernel/virtual memory.png",
      ],
      categories: [filters.SYSTEM],
    },
    {
      title: "OpenSSL Framework",
      projectInfo:
        "Created an automated certificate generation framework. \
         1) Issues certificates based on multiple parameters such as \n\
             1.1) SAN \n\
             1.2) ECC/RSA \n\
             1.3) CSR generation \n\
         2) Maintains a certificate chain in a python class instance \n\
         3) Allows for easy certificate maintenance. \n\
             3.1) This was used to automate NDcPP and EAL4 security certifications. \n\
             3.2) TShark was used to verify cipher suite handshakes in NSX-T",
      client: "VMware",
      technologies: "Python, OpenSSL, TShark",
      industry: "Networking and Security",
      date: "June 16, 2021",
      url: {
        name: "Blog",
        link: "https://gsrikanth.cc/blog/challenger-deep/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/XChikuX",
        twitter: "https://www.twitter.com/CipherSpiegel",
        instagram: "http://www.instagram.com/soulfull_science/",
        mail: "mailto:chat@gsrikanth.cc",
      },
      thumbImage: "images/projects/project-3.jpg",
      sliderImages: [
        "images/projects/lisp/Wireshark.jpg",
      ],
      categories: [filters.SECURITY],
    },
    {
      title: "Psync!",
      projectInfo:
        "Wouldn't it be fun? To find people at events that you know you'd\
         get along with. Who knows? Maybe it, could lead to something more. \
         We plan to make this process simple and intuitive, keep a look out!",
      client: "The world",
      technologies: "Redis, FastAPI, Strawberry GraphQL, HAProxy, Docker, Python",
      industry: "Social Gathering App",
      date: "TBD - In Progress",
      url: {
        name: "Landing Page",
        link: "https://psync.club",
      },
      socialLinks: {
        facebook: "https://www.facebook.com/profile.php?id=100095718455696",
        twitter: "https://x.com/psync_up",
        instagram: "https://www.instagram.com/psync.up/",
        mail: "mailto:srikanth@psync.dev",
      },
      thumbImage: "images/projects/startup/Psync.png",
      sliderImages: [
        "images/projects/startup/Design Diagram.png",
        "images/projects/startup/Network Architecture.png",
        "images/projects/startup/FastAPI_Hypercorn.png",
      ],
      categories: [filters.FULLSTACK],
    },
    // {
    //   title: "WIP",
    //   projectInfo:
    //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    //   client: "Ruby Clinton",
    //   technologies: "iOS, HTML5, CSS3, PHP, Java",
    //   industry: "Art & Design",
    //   date: "June 21, 2020",
    //   url: {
    //     name: "www.example.com",
    //     link: "https://www.example.com",
    //   },
    //   socialLinks: {
    //     facebook: "http://www.facebook.com/",
    //     twitter: "http://www.twitter.com/",
    //     google: "http://www.google.com/",
    //     instagram: "http://www.instagram.com/",
    //     mail: "mailto:example@gmail.com",
    //   },
    //   thumbImage: "images/projects/project-5.jpg",
    //   sliderImages: [
    //     "images/projects/project-1.jpg",
    //     "images/projects/project-5.jpg",
    //   ],
    //   categories: [filters.RESEARCH],
    // },
    {
      title: "GitNotified",
      projectInfo:
        "Worked on a measurement study to identify the effectiveness of Github \
        notifications to facilitate the updating of 3rd party packages for security. \
        This involed analyzing 24 million commits which were sourced using API calls \
        to github. A multithreaded API caller was built with 20 API keys working in parallel. \
        The difference in 3rd party packages from one commit to the next over a timeline was analyzed. \
        The figure you see, is one of the conclusions we arrived at from the data.",
      client: "Wolfpack Security and Privacy Research (WSPR) Lab",
      technologies: "GitHub Archive, MongoDB, Python, GNU parallel, Seaborn",
      industry: "3rd party package security",
      date: "June 21, 2020",
      url: {
        name: "Blog",
        link: "https://gsrikanth.cc/blog/challenger-deep/",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/XChikuX",
        twitter: "https://www.twitter.com/CipherSpiegel",
        instagram: "http://www.instagram.com/soulfull_science/",
        mail: "mailto:chat@gsrikanth.cc",
      },
      thumbImage: "images/projects/project-6.jpg",
      sliderImages: [
        "images/projects/git-notified.png",
      ],
      categories: [filters.RESEARCH],
    },
    {
      title: "AuthentiCall",
      projectInfo:
        "Reaves, B., Blue, L., Abdullah, H., Vargas, L., Traynor, P., & Shrimpton, T. (2017).\n\
         AuthentiCall: Efficient Identity and Content Authentication for Phone Calls. USENIX \n\
         Security Symposium.\n\
         Evaluated the robustness of the protocol by trying to trick \
         RSH (robust speech hash). This was achieved by changing out phonemes from TIMIT audio dataset. \
         We found changes of greater than 6 phonemes in audio was highly detectable. \
         Many different experiments were conducted, however I am not at liberty to explain them all",
      client: "Wolfpack Security and Privacy Research (WSPR) Lab",
      technologies: "Matlab, Python, Seaborn, Audio",
      industry: "Audio Research, Security",
      date: "Aug 3, 2018",
      url: {
        name: "Usenix Presentation",
        link: "https://www.usenix.org/conference/usenixsecurity17/technical-sessions/presentation/reaves",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:chat@gsrikanth.cc",
      },
      thumbImage: "images/projects/project-7.jpg",
      sliderImages: [

      ],
      categories: [filters.RESEARCH, filters.SYSTEM],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
